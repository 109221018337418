<template>
    <psi-form-layout-section
        title="Update Properties"
        icon="mdi-domain-plus"
        btn-label="Update Properties"
        btn-icon="mdi-domain-plus"
        v-if="user.properties"
    >
        <v-alert
            prominent
            outlined
            icon="mdi-information"
            border="top"
            dismissible
            color="info"
        >
            Setting subscribed will enable email notifications for all property
            activities. Setting visible will make the property visible in all
            selection lists.
        </v-alert>

        <template v-for="(property, index) in user.properties">
            <div :key="`div-${index}`" class="d-flex akign-center mb-2">
                <v-icon large class="mr-2" color="primary">mdi-domain</v-icon>
                <div>
                    <h3 class="font-weight-bold">{{ property.name }}</h3>
                    <h4 class="font-weight-regular">
                        {{ property.address_line_1 }} {{ property.city }},
                        {{ property.state }} {{ property.zip }}
                    </h4>
                </div>
            </div>
            <v-card :key="index" class="mb-6" outlined>
                <v-card-text>
                    <psi-form-checkbox-field
                        :value="property.pivot"
                        hide-details
                        :only="['subscribed', 'visible']"
                        cols-class="col-12 col-md-4 col-sm-4"
                        format="button"
                        :key="`checkbox-${index}`"
                        @input="update(property.id, $event)"
                    ></psi-form-checkbox-field>
                </v-card-text>
            </v-card>
            <v-divider
                class="my-3 primary lighten-2"
                :key="`divider-${index}`"
                v-if="index < user.properties.length - 1"
            ></v-divider>
        </template>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "update-properties",
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fields: [],
            model: [],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        update(id, event) {
            console.log(
                "Update for property ID = %s, with event = %o",
                id,
                event
            );
        },
    },
};
</script>

<style scoped>
</style>