var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.properties
    ? _c(
        "psi-form-layout-section",
        {
          attrs: {
            title: "Update Properties",
            icon: "mdi-domain-plus",
            "btn-label": "Update Properties",
            "btn-icon": "mdi-domain-plus"
          }
        },
        [
          _c(
            "v-alert",
            {
              attrs: {
                prominent: "",
                outlined: "",
                icon: "mdi-information",
                border: "top",
                dismissible: "",
                color: "info"
              }
            },
            [
              _vm._v(
                " Setting subscribed will enable email notifications for all property activities. Setting visible will make the property visible in all selection lists. "
              )
            ]
          ),
          _vm._l(_vm.user.properties, function(property, index) {
            return [
              _c(
                "div",
                {
                  key: "div-" + index,
                  staticClass: "d-flex akign-center mb-2"
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { large: "", color: "primary" }
                    },
                    [_vm._v("mdi-domain")]
                  ),
                  _c("div", [
                    _c("h3", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(property.name))
                    ]),
                    _c("h4", { staticClass: "font-weight-regular" }, [
                      _vm._v(
                        " " +
                          _vm._s(property.address_line_1) +
                          " " +
                          _vm._s(property.city) +
                          ", " +
                          _vm._s(property.state) +
                          " " +
                          _vm._s(property.zip) +
                          " "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-card",
                { key: index, staticClass: "mb-6", attrs: { outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("psi-form-checkbox-field", {
                        key: "checkbox-" + index,
                        attrs: {
                          value: property.pivot,
                          "hide-details": "",
                          only: ["subscribed", "visible"],
                          "cols-class": "col-12 col-md-4 col-sm-4",
                          format: "button"
                        },
                        on: {
                          input: function($event) {
                            return _vm.update(property.id, $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              index < _vm.user.properties.length - 1
                ? _c("v-divider", {
                    key: "divider-" + index,
                    staticClass: "my-3 primary lighten-2"
                  })
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }